import React from 'react'

import Layout            from '../components/layout'
import StaffHeroImage    from '../components/images/staff-hero-image'
import HeroWidget        from '../components/hero-widget';
import ButterPageContent from '../components/butter-page-content';
import Footer            from '../components/footer'

import styles from './staff.module.scss';

const Page = () => (
	<Layout page="about">
		<div className={styles.page}>

			<HeroWidget image={StaffHeroImage}>
				<h1>Pastor and Leaders</h1>
			</HeroWidget>

			<section>
				<ButterPageContent slug='pastor-and-leaders'/>
			</section>

			<Footer butter="true"/>
		</div>
	</Layout>
)

export default Page;
